.videoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  background: rgb(0 0 0 / 87%);
  z-index: 10;
}
.videoPop {
  position: fixed;
  top: 15%;
  left: 25%;
  width: 60rem;
  z-index: 11;
  min-height: 100vh;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.videoCounts {
  width: 25rem;
}
.videoCounts p {
  font-size: 2.5rem;
  text-align: end;
  color: #fff;
}
.completeVideo {
  margin-top: 1rem;
  height: 0.6rem;
  background: rgba(196, 196, 196, 1);
  width: 100%;
}
.process {
  width: 0%;
  height: 100%;
  background: rgba(109, 195, 0, 1);
  transition: with 0.5s linear;
}
.complete {
  background: rgba(91, 218, 119, 0.6);
  padding: 1.5rem 2rem;
  color: #fff;
  font-size: 1.5rem;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.complete span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: rgba(91, 218, 119, 0.6);
  background-color: #fff;
  padding: 0.5rem;
  text-align: center;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoName {
  color: #fff;
  font-size: 1.4rem;
  margin-top: -1rem;
}
