.wrapper {
  background: rgba(245, 245, 245, 1);
  min-height: 100vh;
}
.content {
  display: grid;
  margin: 0 2rem;
  grid-template-columns: repeat(3, 1fr);
}
.container {
  margin-top: 2rem;
}
.container h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.ImageWrapper {
  margin: 1rem;
  /* width: 30rem;
    height: 6rem;
    margin-left: 0; */
}
/* .ImageWrapper img{
   
    width: 100%;
    height: 100%;
} */
.otherAdsImage {
  margin-top: 5rem;
}
.extraModal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.extraModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}
