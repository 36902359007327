.loadContainer {
  width: 100%;
  position: absolute;
  height: 100%;
  background: #000000a1;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btnLoader {
  min-width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnLoader:after {
  width: 32px;
  height: 32px;
  border-width: 3px;
}
