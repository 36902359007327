.CardWrapper{
 width: 23rem;
 height: 23rem;
 margin: 1rem 2rem;
}
.imgWrapper{
    width: 22rem;
    height: 17rem;
}
.imgWrapper img{
    width:100%;
    height: 100%;
}
.CardWrapper h1{
    color: #000;
     font-size: 2rem;
     font-weight: 400;
     margin-top:1rem ;
}