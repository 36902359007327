.cardBody {
  padding: 1.5rem;
  margin: 0;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.cardBody legend {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 1rem;
}
.imgWrapper {
  width: 21.3rem;
  height: 16rem;
}
.imgWrapper img {
  width: 100%;
  height: 100%;
}
