body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
h1,
h2 {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media only screen and (max-width: 1920px) {
  body,
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1800px) {
  body,
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1700px) {
  body,
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1600px) {
  body,
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1500px) {
  body,
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1400px) {
  body,
  html {
    font-size: 11px;
  }
}
@media only screen and (max-width: 1300px) {
  body,
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1200px) {
  body,
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1100px) {
  body,
  html {
    font-size: 12px;
  }
}
