.wrapper {
  position: relative;
  min-height: 100vh;
  background: rgba(245, 245, 245, 1);
}
.Content {
  display: grid;
  grid-template-columns: 40% 50%;
  grid-column-gap: 4rem;
  padding: 2rem;
}
.LeftSection {
  margin-top: 2rem;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
.selectImg {
  margin-top: 1rem;
}
.selectImg button {
  background-color: #000;
  color: #fff;
  padding: 1rem;
  font-size: 2rem;
  text-transform: uppercase;
  cursor: pointer;
}
.imageWrapper {
  width: 30rem;
  height: 40rem;
}
.imageWrapper img {
  width: 100%;
  height: 100%;
}
.heading h1 {
  font-size: 3rem;
}
.UpperImg {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* margin: 0; */
}
.UpperImg legend {
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: bold;
  padding: 0 2rem;
}
.UpperImg img {
  width: 10rem;
  height: 15rem;
  border: 2px solid black;
  margin: 1rem;
}

.UpperIm2 img {
  width: 15rem;
  height: 10rem;
  border: 2px solid black;
  margin: 1rem;
}
.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  background: rgba(237, 237, 237, 1);
}
.footer > .price {
  font-size: 2rem;
  align-self: center;
}
.footer button {
  /* background: rgba(202, 202, 202, 1); */
  background-color: #000;
  text-transform: uppercase;
  padding: 1rem;
  color: #fff;
  font-size: 2rem;
  border: none;
  /* float: right; */
  cursor: pointer;
  margin: 1rem;
}
