.wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: rgba(245, 245, 245, 1);
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
  position: relative;
}
.imageWrapper {
  width: 27rem;
  height: 38.5rem;
}
.imageWrapper img {
  width: 100%;
  height: 100%;
}
.options {
  width: 30rem;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.options button {
  padding: 0.6rem;
  border: 2px solid black;
  cursor: pointer;
  font-size: 1.2rem;
}
.options button:nth-child(1){
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}
.saveBtn {
  margin: 2rem;
  background: #000;
  color: #fff;
  padding: 1rem 6rem;
  font-size: 1.5rem;
}
.editOptions{
    position: absolute;
    top: 0;
    right: 12rem;
    width: 25rem;
    border: 2px solid black;
    cursor: pointer;
}
.option{
    padding: 1rem;
    font-size: 1.3rem;
}
.editOptions .option:nth-child(1){
  background: rgba(232, 232, 232, 1);

}
.editOptions .option:nth-child(3){
  background: rgba(105, 180, 235, 1);

}
.footer{
    position: absolute;
    background: rgba(237, 237, 237, 1);
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 6rem;
}
.footer button{
    margin: 0.5rem 2rem;
    padding: 1rem 6rem;
    font-size: 1.3rem;
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    border: none;
}
.footer button:nth-child(1){
    background: rgba(189, 45, 45, 1);
      
}