.HomeContainer {
  position: relative;
  height: 100vh;
}
.HomeContainer
.Content {
  padding: 4rem;
  margin-top: 4rem;
}
.Content h1 {
  margin: 5rem 0;
  font-size: 7rem;
}
.formInputField {
  display: grid;
  grid-template-columns: 1fr 5rem;
  grid-template-rows: 5rem;
  border: 2px solid black;
  margin-bottom: 2rem;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  position: relative;
}
.formInputField input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 2rem;
  padding: 0 1.5rem;
  cursor: pointer;
}
.formInputField label {
  justify-self: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: rgba(196, 196, 196, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.formSubmit {
  position: absolute;
  bottom: 8rem;
  right: 0rem;
  width: 100%;
}
.formSubmit button {
  position: absolute;
  right: 4rem;
  padding: 1rem 2rem;
  color: #fff;
  background-color: #000;
  font-size: 3rem;
}
.formSubmit span {
  background-color: #fff;
  position: absolute;
  font-size: 2rem;
  padding: 1rem 2rem;
  left: 4rem;
  color: #000;
  cursor: pointer;
}
