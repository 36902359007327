*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.callouts__container {
  width: 22%;
  z-index: 4;
}

/* BASE STYLES FOR EACH */
.callouts__container > div {
  /* Real styles */
  position: relative;
  /* width: 25%; */
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 0 10px #c1c1c1;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #fff;
  font-size: 1.3rem;
}
.callout__index {
  grid-area: 1 / 1 / 2 / 2;
  border: 1px solid #9e9e9e;
  border-radius: 50%;
  padding: 4px 12px;
  cursor: default;
}
.callout__text {
  word-break: break-word;
  margin: 0 1rem;
}
.callout__btn {
  border-bottom: 1px solid #787878;
  cursor: pointer;
}
.nextbtn__icon {
  padding-left: 0.3rem;
}
.callout_bullet {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-top: 0.7rem;
  background: #c4c4c4;
}
.callouts--triangle:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  /* border: 10px solid transparent; */
  /* border-bottom: 32px solid rgba(193,193,193,0.5); */
  z-index: 2;
}
.callouts--triangle:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  z-index: 3;
}

/* LEFT BOTTOM */
.callouts--leftBottom:after {
  left: 25px;
  bottom: -32px;
  border-top: 24px solid rgba(0, 0, 0, 0.7);
}

/*LEFT TOP */
.callouts--leftTop:after {
  left: 25px;
  top: -33px;
  border-bottom: 25px solid rgba(0, 0, 0, 0.7);
}

/*Right TOP */
.callouts--rightTop:after {
  right: 25px;
  top: -33px;
  border-bottom: 25px solid rgba(0, 0, 0, 0.7);
}

/*RIGHT BOTTOM */
.callouts--rightBottom:after {
  right: 25px;
  bottom: -32px;
  border-top: 24px solid rgba(0, 0, 0, 0.7);
}
