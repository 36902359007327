.HomeContainer nav {
  background: #000;
  width: 100%;
  height: 5rem;
}
.homeContent {
  margin: 5rem 4rem;
}
.aboutForm h1 {
  font-size: 5rem;
  text-transform: capitalize;
}
.aboutForm p {
  font-size: 2.3rem;
  margin: 1.5rem 0;
}
.formContent {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.formInputField {
  display: grid;
  grid-template-columns: 1fr 5rem;
  grid-template-rows: 5rem;
  border: 2px solid black;
  margin-bottom: 2rem;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
.formInputField input,
.inputDrop {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 2rem;
  padding: 0 1.5rem;
  cursor: pointer;
}
.formInputField label,
.inputDropLogo {
  justify-self: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: rgba(196, 196, 196, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.FormDropDown {
  margin-top: -2rem;
  border: 2px solid black;
  border-top: none;
  margin-bottom: 2rem;
}
.dropOption {
  font-size: 2rem;
  cursor: pointer;
}
.inputDrop,
.dropOption {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
}
.inputDrop h1 {
  font-size: 2rem;
  text-transform: capitalize;
}
.formSubmit {
  position: relative;
  margin-top: 3rem;
}
.formSubmit button {
  position: absolute;
  right: 0;
  padding: 1rem 2rem;
  color: #fff;
  background-color: #000;
  font-size: 3rem;
  cursor: pointer;
}

.formSubmit button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.formSubmit_2Btn {
  position: relative;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formSubmit_2Btn button {
  padding: 1rem 2rem;
  color: #fff;
  background-color: #000;
  font-size: 3rem;
  cursor: pointer;
}
.formSubmit_2Btn button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
