.videoWrapper {
  position: relative;
  width: 18rem;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.videoWrapper_active {
  cursor: pointer;
  transition: 0.25s;
  transform: scale(1);
}
.videoWrapper_active:hover {
  transform: scale(1.2);
  z-index: 4;
}

.cardImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.cardImage img {
  width: 100%;
  height: 100%;
}
.playBTN {
  position: relative;
  bottom: 1.3rem;
}
.CardTitle {
  color: #fff;
  width: 100%;
  position: absolute;
  bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CardTitle h1 {
  font-size: 1.5rem;
  padding: 0 1rem;
}
.CardTitle p {
  font-size: 1.3rem;
  padding: 0 1rem;
}
.lockedVideos {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.664);
}
