nav {
  background: #000;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backBtn {
  color: #fff;
  font-size: 2rem;
  padding-left: 2rem;
  cursor: pointer;
}
.appIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  
}
.appInfo{
    margin-right: 1rem;
}
.otherAppIcons {
  display: flex;
  background: rgba(97, 97, 97, 1);
  width: 10rem;
  height: 5rem;
  justify-content: space-evenly;
  align-items: center;
}
