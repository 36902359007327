.videoOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.753);
  z-index: 15;
}
.Content {
  position: fixed;
  top: 15%;
  left: 25%;
  width: 60rem;
  z-index: 15;
  background-color: #fff;
  transform: scale(0.9);
}
.Content nav img {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  cursor: pointer;
}
.header {
  margin-top: 1rem;
  border-bottom: 2px solid rgba(16, 205, 58, 0.2);
}
.header h1 {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  color: rgba(16, 205, 58, 1);
}
.completeLogo {
  margin: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.completeLogo img {
  border-radius: 50%;
  border: 16px solid rgba(148, 235, 167, 0.329);
  /* animation: 0.4s  allDone linear forwards; */
}
/* @keyframes allDone {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
} */
.footer {
  margin-bottom: 3rem;
}
.footer h1 {
  font-size: 2.5rem;
  text-transform: capitalize;
  text-align: center;
}
.footer h1:last-child {
  color: rgba(16, 205, 58, 1);
}

.stackFooter {
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-direction: column; */
}
.stackFooter h1 {
  font-size: 2rem;
  line-height: 40px;
  text-align: center;
  color: #0094e8;
  margin-bottom: 1rem;
  cursor: pointer;
}
.stackFooter .backBtn {
  padding: 1.25rem 1rem;
  color: #fff;
  font-size: 1.25rem;
  background: black;
  text-decoration: none;
  border-radius: 2px;
}
