.cardBody {
    padding: 1.5rem;
    margin: 0;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 15rem;
    height: 20rem;
    
  }
  .cardBody legend {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0 1rem;
  }
  .imgWrapper {
    width: 13rem;
    height: 17rem;
  }
  .imgWrapper img {
    width: 100%;
    height: 100%;
  }
  