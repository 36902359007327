.wrapper {
  /* position: relative;
  width: 100%;
  min-height: 100vh; */
  overflow: hidden;
  background: rgba(245, 245, 245, 1);
  height: 100vh;
}
.Content {
  display: grid;
  grid-template-columns: 25rem 1fr;
  margin-top: 1rem;
  grid-gap: 2rem;
}
.standSection {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 85vh;
}
.otherSections {
  display: grid;
  grid-template-columns: 70rem auto auto;
  grid-template-rows: 1fr;
  overflow-x: scroll;
  overflow-y: hidden;
}
.essentialSection {
  padding: 1rem;
}
.heading h1 {
  font-size: 2.5rem;
}
.cardWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  padding: 1rem;
  padding-right: 5rem;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
}
.GreenSection {
  margin-top: 4rem;
}
.GreenCardWrapper {
  display: grid;
  grid-template-columns: repeat(2, 20rem);
  grid-gap: 2rem;
  padding: 1rem;
  padding-right: 5rem;
  margin-left: 3rem;
  border-right: 2px solid rgba(0, 0, 0, 0.3);
}
.jumboSection {
  margin: 3rem 4rem;
  width: 20rem;
  height: 50rem;
}
.jumboSection img {
  width: 85%;
  height: auto;
}
