.videContainer {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  user-select: none;
}
.videContainer nav {
  background: #fff;
  width: 100%;
  height: 5rem;
}
.videContainer nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.47);
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 2rem;
  cursor: pointer;
}
.videoContent {
  padding: 4rem;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.aboutVideo h1 {
  font-size: 5rem;
  text-transform: capitalize;
  font-weight: bold;
}
.videoCounts p {
  font-size: 2.5rem;
  text-align: right;
}
.completeVideo {
  margin-top: 1rem;
  height: 0.6rem;
  background: rgba(196, 196, 196, 1);
  width: 25rem;
}
.process {
  width: 0%;
  height: 100%;
  background: rgba(109, 195, 0, 1);
  transition: with 0.5s linear;
}
.AllVideos {
  padding:1rem 4rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  grid-row-gap: 3rem;
  justify-items: center;
  align-items: center;
}
